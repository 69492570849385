<template>
  <div>
    <div 
    class="row justify-content-center align-items-center animate__animated
     animate__fadeInUp animate__slow">
<!-- {{  this.fetchInterval }} -->
      <div class="col p-0 m-0">
        <div class="text-center"
          :class="timer.minutes<10?'text-danger':''">
          <span class="fw-bold " style="font-size: 230px;line-height: 180px;">
            {{ timer.minutes }}
          </span>
          <span class="fw-bold text-secondary"  style="font-size: 150px;line-height: 150px;">
             {{ timer.seconds }}
          </span>
        </div>
      </div> 
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  props: {
    reservation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timer: {
        minutes: 0,
        seconds: 0,
      },
      interval: null, // To store the interval reference for timer
      fetchInterval: null, // To store the interval reference for fetchReservation retry
      refreshInterval: 1000, // Refresh every second for the timer display
    };
  },
  computed: {
    // ...mapGetters("reservation", {
    //   reservation: "getReservation",
    // }),
  },
  watch: {
    reservation: {
      handler() {
        if (this.reservation.startTime && this.reservation.endTime) {
          this.startTimer(); // Start the timer when reservation data is available
          if (this.fetchInterval) {
            clearInterval(this.fetchInterval); // Stop fetching once reservation is available
          }
        } else {
          // If reservation is null, keep trying to fetch every 10 seconds
          this.retryFetchReservation();
        }
      },
      immediate: true, // Trigger the handler when component mounts
    },
  },
  methods: {
    async fetchReservation() {
      // Fetch reservation details using the booking reference
      // await this.$store.dispatch("reservation/show", this.bookingReference);
    },
    retryFetchReservation() {
      // Set a 10-second interval to re-fetch the reservation if it's null
      if (!this.fetchInterval) {
        this.fetchInterval = setInterval(async () => {
          await this.fetchReservation();
          if (this.reservation.startTime && this.reservation.endTime) {
            clearInterval(this.fetchInterval); // Stop retrying if data is available
            this.fetchInterval = null; // Clear reference
          }
        }, 4000); // Retry every 10 seconds
      }
    },
    startTimer() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        this.calculateRemainingTime();
      }, this.refreshInterval);
    },
    calculateRemainingTime() {
      const now = new Date();
      const end = new Date(`${this.reservation.date}T${this.reservation.endTime}`);
      const diff = (end - now) / 1000; // Difference in seconds

      console.log(diff);
      if (diff <= 0) {
        clearInterval(this.interval); // Stop the timer if time is up
        this.timer.minutes = 0;
        this.timer.seconds = 0;
      } else {
        this.timer.minutes = Math.floor(diff / 60); // Get remaining minutes
        this.timer.seconds = Math.floor(diff % 60); // Get remaining seconds
      }
    },
  },
  async mounted() {
    await this.fetchReservation(); // Fetch reservation details on mount
    if (!this.reservation.startTime || !this.reservation.endTime) {
      this.retryFetchReservation(); // Start retry mechanism if reservation data is missing
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval); // Clear the timer interval on component destruction
    }
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval); // Clear the fetch retry interval on component destruction
    }
  },
};
</script>


