<template>
  <div class=" ">
    
      <MatchCardVue :terrain="$route.params.reference"/>
  </div>
</template>

<script>
import MatchCardVue from '../../components/Cards/MatchCard.vue';
export default {
  components: {
    MatchCardVue,
    
  }, 
  data() {
    return {};
  },
  
};
</script>

<style>

</style>
