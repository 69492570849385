<template>
  <div  class="rounded">
    <div class="text-center ">
      
      <span class="text animate__animated animate__fadeInRight animate__slow">
        Match Terminé
        <!-- <br> Thank You -->
        <br> {{ teamNameA }}
        <br> VS {{ teamNameB }}
      </span>
      
    </div>
  </div>
</template>


<script>
export default {
  props: {
    teamNameA: {
      type: String,
      required: true
    },
    teamNameB: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>


.text {
  white-space: nowrap;
  z-index: 1;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 900;
  line-height: 135px;
  letter-spacing: 10px;
  /* -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ff5e00;  */
  font-size: 110px;
  color: #ff5e00;
  background-color: RGBA(0, 0, 0, 0);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
