import { render, staticRenderFns } from "./GoalAlert.vue?vue&type=template&id=6a9a0244&scoped=true"
import script from "./GoalAlert.vue?vue&type=script&lang=js"
export * from "./GoalAlert.vue?vue&type=script&lang=js"
import style0 from "./GoalAlert.vue?vue&type=style&index=0&id=6a9a0244&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a9a0244",
  null
  
)

export default component.exports