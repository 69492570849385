<template>
  <div  class="rounded">
    <div class=" text-center ">
      <span class="text text-center animate__animated animate__fadeInRight animate__slow">
        Aucun Match
        <br> N'est Encore
        <br> Programmé
      </span>
    </div>

    <PartnersCardVue/>
  </div>
</template>
<script>
import PartnersCardVue from './PartnersCard.vue'
export default {
  components: {
    PartnersCardVue
  }
}
</script>
<style scoped>

.text {
  white-space: nowrap;
  z-index: 1;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 900;
  line-height: 125px;
  /* -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ff5e00;  */
  font-size: 110px;
  color: #ff5e00;
  background-color: RGBA(0, 0, 0, 0);
  background-color: RGBA(0, 0, 0, 0);
}
</style>
